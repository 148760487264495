import { A as zt, B as Jt, C as Yt, F as Qt, J as Zt, L as Xt, a as te } from "./Fieldset-Jnfx0L5O.mjs";
import { g as W, r as U, a as z, b as J, s as Y, P as Q, c as Z, d as X } from "./VirtualScroll-Dr1k0ylo.mjs";
import { A as ne, C as se, e as oe, f as re, h as ae, D as ie, m as ce, i as le, j as ue, k as pe, l as fe, o as me, L as ye, p as de, q as ge, G as _e, H as he, M as be, O as xe, n as Te, T as Ce, Q as ke, R as Ae, V as je, I as ve, x as Pe, y as Re, w as Ne, E as Se, z as Fe, B as we, t as Be, v as Ee, K as Oe, J as Ie, F as Le, u as Me, S as $e, N as De } from "./VirtualScroll-Dr1k0ylo.mjs";
import { j as m, c as E, L as tt, T as et, I as nt } from "./ToggleSwitch-B70KlqlG.mjs";
import { C as qe, H as He, b as Ke, d as Ge, R as We, a as Ue, e as ze, f as Je } from "./ToggleSwitch-B70KlqlG.mjs";
import { useRef as j, useState as w, useEffect as v, useCallback as I, useMemo as C, useLayoutEffect as st, Children as B, isValidElement as N, cloneElement as ot } from "react";
import { createPortal as rt } from "react-dom";
async function at(t) {
  if (!t)
    return !1;
  await navigator.clipboard.writeText(t);
}
function* it(t) {
  for (let e = 0; e < t; ++e)
    yield e;
}
const Mt = [
  "text",
  "number",
  "email",
  "password",
  "search",
  "tel",
  "date",
  "datetime-local",
  "time",
  "url",
  "color"
];
function ct(t, e, a) {
  const n = j(null), o = j(null), [s, i] = w(null);
  return v(() => {
    o.current = e ?? null;
  }, [e]), v(() => (n.current = new MutationObserver((c) => {
    for (const u of c)
      (typeof o.current != "function" || o.current(u)) && i(u);
  }), n.current.observe(t.current, a), () => {
    var c;
    (c = n.current) == null || c.disconnect();
  }), []), s;
}
function $t(t, e) {
  const a = I(
    (s) => s.type === "attributes" && s.attributeName === e,
    [e]
  ), n = ct(t, a, { attributes: !0 });
  return C(
    () => {
      var s;
      return { oldValue: (n == null ? void 0 : n.oldValue) ?? null, newValue: (s = t.current) == null ? void 0 : s.getAttribute(e) };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [n, e]
  );
}
const D = typeof window < "u" ? st : v;
function V(t) {
  const e = j();
  return v(() => {
    e.current = t;
  }, [t]), e;
}
function lt(t, e, a) {
  const n = /* @__PURE__ */ new Map();
  return B.forEach(t, (o) => {
    var u;
    if (!N(o) || !o.key) return;
    const s = e.get(o.key);
    if (!s) return;
    const c = { top: (u = s.getBoundingClientRect()) == null ? void 0 : u.top, parentTop: a };
    n.set(o.key, c);
  }), n;
}
function S(t) {
  return t === null ? "" : t.replace(".$", "");
}
function q(t, e) {
  const a = /* @__PURE__ */ new Set(), n = [];
  for (const o of t) {
    const s = o.key;
    a.has(s) || (a.add(s), n.push(o));
  }
  for (const o of e) {
    const s = o.key;
    a.has(s) || (a.add(s), n.push(o));
  }
  return n;
}
function Dt({
  children: t,
  animationLengthInMs: e,
  tagToRenderSpy: a = "div"
}) {
  const n = j(null), o = I(
    (l, r, y) => `transform ${l ?? e}ms ease, clip-path ${r ?? e}ms ease, opacity ${y ?? e}ms ease`,
    [e]
  ), s = "inset(0)", i = "inset(0 0 100% 0)", [c, u] = w(/* @__PURE__ */ new Map()), p = V(t), _ = V(c), [h, k] = w([]), T = j(/* @__PURE__ */ new Map());
  D(() => {
    var y, d;
    const l = ((d = (y = n.current) == null ? void 0 : y.parentElement) == null ? void 0 : d.getBoundingClientRect().top) ?? 0;
    u(lt(t, T.current, l));
    const r = p.current;
    if (r) {
      const b = new Set(
        B.map(t, (g) => N(g) ? S(g.key) : null)
      ), x = B.toArray(r).filter(
        (g) => N(g) && !b.has(S(g.key))
      );
      x.length && k((g) => q(g, x));
    }
  }, [t, p, n]);
  const A = C(
    () => q(B.toArray(t), h).filter((l) => N(l) ? l.key ? !0 : (console.warn("AnimateList got a child with no key, skipping"), !1) : (console.warn("AnimateList got an invalid child"), !1)),
    [t, h]
  );
  return D(() => {
    var b;
    let l = 0;
    const r = [], y = [], d = new AbortController();
    for (const x of A) {
      if (!N(x) || !x.key) continue;
      const g = S(x.key), P = c.get(g), R = (b = _.current) == null ? void 0 : b.get(g), f = T.current.get(g);
      if (!f) continue;
      if (!P) {
        r.push(
          requestAnimationFrame(() => {
            f.style.transition = o(), f.style.transform !== i && (f.style.clipPath = s, f.style.opacity = "1"), r.push(
              requestAnimationFrame(() => {
                f.style.clipPath = i, f.style.opacity = "0";
              })
            );
          })
        ), f.addEventListener(
          "transitionend",
          function(K) {
            K.propertyName === "clip-path" && (k(
              (G) => G.filter(
                ($) => N($) && S($.key) !== g
              )
            ), T.current.delete(g), d.abort());
          },
          { signal: d.signal }
        );
        continue;
      }
      if (!R || f.style.opacity === "0") {
        const M = Math.round(l * e / 2);
        l++, r.push(
          requestAnimationFrame(() => {
            f.style.clipPath = i, f.style.opacity = "0", f.style.transition = o(), y.push(
              setTimeout(() => {
                r.push(
                  requestAnimationFrame(() => {
                    f.style.clipPath = s, f.style.opacity = "1";
                  })
                );
              }, M)
            );
          })
        );
        continue;
      }
      const L = R.top - R.parentTop - (P.top - P.parentTop);
      L && r.push(
        requestAnimationFrame(() => {
          f.style.transform = `translateY(${L}px)`, f.style.transition = o(0), r.push(
            requestAnimationFrame(() => {
              f.style.transition = o(), f.style.transform = "translateY(0)";
            })
          );
        })
      );
    }
    return () => {
      y.map(clearTimeout), r.map(cancelAnimationFrame), d.signal.aborted || d.abort();
    };
  }, [e, c, o, A]), /* @__PURE__ */ m.jsxs(m.Fragment, { children: [
    /* @__PURE__ */ m.jsx(a, { ref: n, style: { maxBlockSize: 0 } }),
    A.map((l) => ot(l, {
      ref: (r) => {
        T.current.set(S(l.key), r);
      }
    }))
  ] });
}
const ut = "_number_j91ks_7", pt = {
  number: ut
};
function ft(t) {
  const e = typeof t == "number" ? t.toString() : t;
  let a = "", n = "";
  const o = [], s = [];
  let i = "", c = !1;
  const u = (p) => typeof p == "string" && /[0-9]/.test(p);
  for (let p = 0; p <= e.length; p++) {
    const _ = e[p], h = u(_);
    h && !c && (s.length ? i && o.push(i) : a = i, i = ""), !h && c && (s.push(i), i = ""), !h && !c && (n = i), c = h, i += _;
  }
  return {
    prefix: a,
    suffix: n,
    separators: o,
    integers: s
  };
}
function mt({ num: t, separator: e }) {
  const a = parseInt(t, 10).toString(), n = Math.max(t.length - a.length, 0);
  return /* @__PURE__ */ m.jsx(
    "span",
    {
      "data-zero-pad": "0".repeat(n),
      "data-separator": e ?? "",
      style: {
        "--number": t
      }
    }
  );
}
function Vt({
  children: t,
  style: e,
  className: a,
  animationDurationInMs: n = 1e3,
  maxNumberScale: o = 2
}) {
  const s = j(null), [i, c] = w(0), u = C(() => ft(t), [t]), p = C(() => {
    const l = u.integers.length - 1;
    return parseFloat(
      u.integers.slice(0, l).join("") + (u.separators[l - 1] ? "." + u.integers[l] : u.integers[l])
    );
  }, [u]);
  v(() => {
    if (isNaN(p)) return;
    const l = s.current ?? 0, r = p - l;
    if (s.current = p, s.current === null)
      return c(0);
    c(r);
    const y = setTimeout(() => {
      c(0);
    }, n);
    return () => clearTimeout(y);
  }, [n, p]);
  const _ = C(
    () => Math.max(Math.max(o - 1, 0) * ((Math.log10(i / 100) + 3) / 4) + 1, 1),
    [i, o]
  );
  if (isNaN(p)) return null;
  const { prefix: h, suffix: k, separators: T, integers: A } = u;
  return /* @__PURE__ */ m.jsxs(
    "div",
    {
      className: E(pt.number, a),
      "data-prefix": h,
      "data-suffix": k,
      style: {
        ...e,
        "--number-scale": _,
        "--number-animation-duration": `${n}ms`
      },
      children: [
        A.map((l, r) => {
          const y = T[r], d = A.length - 1 - r;
          return /* @__PURE__ */ m.jsx(mt, { num: l, separator: y }, d);
        }),
        /* @__PURE__ */ m.jsx("span", { className: "sr-only", children: t }, "sr-only")
      ]
    }
  );
}
const yt = "_container_kj9ur_2", dt = "_screen_kj9ur_8", gt = "_particle_kj9ur_86", _t = "_circle_kj9ur_111", F = {
  container: yt,
  screen: dt,
  particle: gt,
  "x-axis": "_x-axis_kj9ur_1",
  "y-axis": "_y-axis_kj9ur_1",
  circle: _t,
  "rotation-0": "_rotation-0_kj9ur_119",
  "rotation-1": "_rotation-1_kj9ur_125",
  "rotation-2": "_rotation-2_kj9ur_131",
  "rotation-3": "_rotation-3_kj9ur_137",
  "rotation-4": "_rotation-4_kj9ur_143",
  "rotation-5": "_rotation-5_kj9ur_149"
}, ht = 0.5, bt = 12, xt = "120vh", Tt = 1e3, Ct = 100, kt = 2200, At = ["#FFC700", "#FF0000", "#2E3191", "#41BBC7"];
function jt(t, e) {
  const a = 360 / t;
  return Array.from(it(t)).map((n) => ({
    color: e[n % e.length],
    degree: a * n
  }));
}
function qt({
  particleCount: t = Ct,
  duration: e = kt,
  colors: a = At,
  particleSize: n = bt,
  force: o = ht,
  height: s = xt,
  width: i = Tt,
  zIndex: c,
  onComplete: u,
  ...p
}) {
  const [_, h] = w(), k = C(() => jt(t, a), [t, a]), T = C(() => k.map((r) => r.degree), [k]), A = C(() => W(T, s, i), [T, i, s]), l = j(null);
  return v(() => {
    var P;
    const r = l.current;
    if (!r) return;
    let y = 0, d = 0;
    const b = (P = r.closest("dialog")) == null ? void 0 : P.firstElementChild;
    if (b) {
      const R = b.getBoundingClientRect();
      y = R.top, d = R.left;
    }
    const { top: x, left: g } = r.getBoundingClientRect();
    h({ top: x - y, left: g - d });
  }, []), v(() => {
    if (typeof u != "function") return;
    const r = setTimeout(u, e);
    return () => clearTimeout(r);
  }, [e]), /* @__PURE__ */ m.jsx("div", { ref: l, className: F.container, ...p, children: _ && rt(
    /* @__PURE__ */ m.jsx(
      "div",
      {
        className: F.screen,
        style: { ...U, ...A, ...c ? { zIndex: c } : null },
        children: /* @__PURE__ */ m.jsx("div", { style: { position: "absolute", top: _.top, left: _.left }, children: k.map((r, y) => {
          const d = Math.round(Math.random() * (J.length - 1)), b = Y(d), x = z(r, e, o, n, b);
          return /* @__PURE__ */ m.jsx(
            "div",
            {
              className: E(F.particle, F[`rotation-${d}`], {
                [F.circle]: b
              }),
              style: x,
              children: /* @__PURE__ */ m.jsx("div", {})
            },
            r.degree
          );
        }) })
      }
    ),
    // we target the inner modal div
    document.getElementById("bp-portal-target-inert") ?? document.body
  ) });
}
const vt = "_copyTextContainer_rj38r_2", Pt = "_copyTextInput_rj38r_7", H = {
  copyTextContainer: vt,
  copyTextInput: Pt
};
function Ht({
  textToCopy: t,
  showPreview: e = !1,
  previewLabel: a,
  previewHint: n,
  children: o,
  onTextCopied: s,
  onError: i,
  className: c
}) {
  const u = I(() => {
    t && at(t).then(() => s == null ? void 0 : s(t)).catch((_) => i == null ? void 0 : i(_));
  }, [t, s, i]), p = C(() => o(u), [o, u]);
  return e ? /* @__PURE__ */ m.jsxs(tt, { className: E(H.copyTextContainer, c), children: [
    /* @__PURE__ */ m.jsx(
      et,
      {
        className: H.copyTextInput,
        readOnly: !0,
        label: a,
        description: n,
        value: t
      }
    ),
    p
  ] }) : p;
}
const Rt = "_tooltipContent_upgbl_1", Nt = "_spyElement_upgbl_12", St = "_opacityTransition_upgbl_18", Ft = "_opacityTransitionOpen_upgbl_23", O = {
  tooltipContent: Rt,
  spyElement: Nt,
  opacityTransition: St,
  opacityTransitionOpen: Ft
};
function Kt(t) {
  return /* @__PURE__ */ m.jsx(
    Q,
    {
      ...t,
      Anchor: Z,
      triggerEvents: ["hover", "focus"],
      contentClassName: O.tooltipContent,
      contentWrapperClassName: O.opacityTransition,
      popupOpenWrapperClassName: O.opacityTransitionOpen,
      preferredPopupPosition: X.TOP
    }
  );
}
const wt = "_tooltipTrigger_36bik_1", Bt = {
  tooltipTrigger: wt
}, Gt = ({ type: t = "question", title: e = "More information", className: a }) => /* @__PURE__ */ m.jsx("button", { className: E(Bt.tooltipTrigger, a), type: "button", children: /* @__PURE__ */ m.jsx(nt, { name: t, title: e }) });
export {
  zt as Alert,
  ne as Alignment,
  Mt as AllowedInputTypes,
  se as AmountField,
  Dt as AnimateList,
  Vt as AnimateNumber,
  Jt as Badge,
  oe as Button,
  Yt as Callout,
  qe as Card,
  re as Checkbox,
  ae as ColorInput,
  qt as Confetti,
  Ht as CopyText,
  ie as Dropdown,
  ce as DropdownLabel,
  le as DropdownMenu,
  ue as DropdownMenuItem,
  pe as DropdownMenuSeparator,
  fe as DropdownTrigger,
  Qt as Fieldset,
  He as Heading,
  nt as Icon,
  me as IconButton,
  Zt as JSONPrettyPrint,
  ye as LayoutAccordion,
  de as LayoutAccordionItem,
  Xt as LayoutCenter,
  tt as LayoutCluster,
  te as LayoutGrid,
  ge as LayoutModal,
  Ke as LayoutStack,
  Ge as LoadingSkeleton,
  _e as POPUP_CONTAINER_ATTR,
  he as POPUP_TRIGGER_ATTR,
  Q as Popup,
  be as PopupContext,
  X as Position,
  xe as RadioButton,
  We as RangeInput,
  Te as Select,
  et as TextInput,
  Ue as TextInputWrapper,
  ze as TextLink,
  Ce as Textarea,
  Je as Tile,
  ke as ToggleButton,
  Ae as ToggleSwitch,
  Kt as Tooltip,
  Gt as TooltipTrigger,
  je as VirtualScroll,
  ve as findElementPopupParent,
  Pe as findFirstFocusableChild,
  Re as findFirstFocusableChildOrRoot,
  Ne as findFocusableChildren,
  Se as findNextFocusableElementExcludingChildren,
  Fe as findNextFocusableSibling,
  we as findPreviousFocusableSibling,
  Be as getAriaDescribedBy,
  Ee as getAriaRole,
  Oe as getScrollParent,
  Ie as isElementTriggerWithinTarget,
  Le as tick,
  Me as useAccordionContext,
  $t as useAttributeObserver,
  $e as useForwardedRef,
  ct as useMutationObserver,
  De as usePopupContext
};
